
import { Component, Mixins } from 'vue-property-decorator'
import { InitConfig, InstanceConfig } from '@/store/config/types'
import StateMixin from '@/mixins/state'
import { appInit } from '@/init'
import { Waits } from '@/globals'

@Component({})
export default class SystemPrinters extends Mixins(StateMixin) {
  waits = Waits

  instanceDialogOpen = false

  get instanceName () {
    return this.$store.state.config.uiSettings.general.instanceName
  }

  get instances () {
    return this.$store.getters['config/getInstances']
  }

  mounted () {
    // If we have no api's configured at all, open the dialog.
    if (this.$store.state.config.apiUrl === '') {
      this.instanceDialogOpen = true
    }
  }

  removeInstance (instance: InstanceConfig) {
    this.$store.dispatch('config/removeInstance', instance)
  }

  addInstanceDialog () {
    this.instanceDialogOpen = true
  }

  activateInstance (instance: InstanceConfig) {
    // Close the drawer
    this.$emit('click')
    if (!instance.active) {
      // Close the socket.
      this.$socket.close()

      // Re-init the app.
      appInit(instance, this.$store.state.config.hostConfig)
        .then((config: InitConfig) => {
          // Reconnect the socket with the new instance url.
          if (config.apiConfig.socketUrl && config.apiConnected && config.apiAuthenticated) {
            this.$socket.connect(config.apiConfig.socketUrl)
          }
        })
    }
  }
}
