import { render, staticRenderFns } from "./AppSlider.vue?vue&type=template&id=36220832&scoped=true&"
import script from "./AppSlider.vue?vue&type=script&lang=ts&"
export * from "./AppSlider.vue?vue&type=script&lang=ts&"
import style0 from "./AppSlider.vue?vue&type=style&index=0&id=36220832&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36220832",
  null
  
)

export default component.exports