
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { Globals } from '@/globals'
import linkExternalUrls from '@/util/link-external-urls'

@Component({
  components: {}
})
export default class AppWarnings extends Mixins(StateMixin) {
  get docsUrl () {
    return Globals.DOCS_REQUIRED_CONFIGURATION
  }

  get moonrakerDocsUrl () {
    return Globals.DOCS_MOONRAKER_COMPONENTS
  }

  get printerWarningsTxt () {
    return this.$t('app.general.error.app_setup_link', {
      url: this.docsUrl
    })
  }

  get moonrakerFailedComponentsTxt () {
    return this.$t('app.general.error.components_config', {
      url: this.moonrakerDocsUrl
    })
  }

  get appName () {
    return Globals.APP_NAME
  }

  get printerWarnings () {
    return this.$store.getters['printer/getPrinterWarnings']
  }

  get klipperWarnings () {
    return this.$store.getters['printer/getKlipperWarnings']
  }

  get moonrakerFailedComponents () {
    return this.$store.getters['printer/getMoonrakerFailedComponents']
  }

  get moonrakerWarnings () {
    return this.$store.getters['printer/getMoonrakerWarnings']
  }

  linkExternalUrls = linkExternalUrls
}
