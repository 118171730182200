
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({})
export default class AppFooter extends Vue {
  get appInfo () {
    return this.$store.state.version.fluidd
  }
}
