
import { Component, Mixins, Prop } from 'vue-property-decorator'
import PrinterLimits from '@/components/widgets/limits/PrinterLimits.vue'
import StateMixin from '@/mixins/state'

@Component({
  components: {
    PrinterLimits
  }
})
export default class PrinterLimitsCard extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: true })
  public enabled!: boolean

  get inLayout (): boolean {
    return (this.$store.state.config.layoutMode)
  }
}
