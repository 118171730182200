var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('v-app'):_c('v-app',{staticClass:"fluidd"},[_c('app-tools-drawer',{model:{value:(_vm.toolsdrawer),callback:function ($$v) {_vm.toolsdrawer=$$v},expression:"toolsdrawer"}}),_c('app-nav-drawer',{model:{value:(_vm.navdrawer),callback:function ($$v) {_vm.navdrawer=$$v},expression:"navdrawer"}}),_c('app-bar',{on:{"toolsdrawer":_vm.handleToolsDrawerChange,"navdrawer":_vm.handleNavDrawerChange}}),(_vm.flashMessage)?_c('flash-message',{attrs:{"text":_vm.flashMessage.text,"type":_vm.flashMessage.type,"timeout":_vm.flashMessage.timeout},model:{value:(_vm.flashMessage.open),callback:function ($$v) {_vm.$set(_vm.flashMessage, "open", $$v)},expression:"flashMessage.open"}}):_vm._e(),(_vm.isMobile && _vm.authenticated && _vm.socketConnected)?_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"x-small":"","fab":"","fixed":"","bottom":"","left":"","color":"error"},on:{"click":function($event){return _vm.emergencyStop()}}},[_c('v-icon',[_vm._v("$estop")])],1):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"pa-2 pa-sm-4",class:{ 'fill-height': _vm.$route.meta.fillHeight },attrs:{"fluid":""}},[(
            (_vm.socketConnected && _vm.apiConnected) &&
              (!_vm.klippyReady || _vm.hasWarnings) &&
              !_vm.inLayout &&
              _vm.$route.path !== '/login'
          )?_c('v-row',[_c('v-col',[_c('klippy-status-card')],1)],1):_vm._e(),(
            (_vm.socketConnected && _vm.apiConnected) ||
              (!_vm.authenticated && _vm.apiConnected)
          )?_c('router-view'):_vm._e()],1),(
          (!_vm.socketConnected && !_vm.apiConnected) ||
            (!_vm.socketConnected && _vm.authenticated))?_c('socket-disconnected'):_vm._e(),_c('updating-dialog')],1),_c('app-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }