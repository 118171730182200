
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { defaultState } from '@/store/config/index'

@Component({
  components: {}
})
export default class FileEditorSettings extends Mixins(StateMixin) {
  get confirmDirtyEditorClose (): boolean {
    return this.$store.state.config.uiSettings.editor.confirmDirtyEditorClose
  }

  set confirmDirtyEditorClose (value: boolean) {
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.editor.confirmDirtyEditorClose',
      value,
      server: true
    })
  }

  get autoEditExtensions (): string[] {
    return this.$store.state.config.uiSettings.editor.autoEditExtensions
  }

  set autoEditExtensions (value: string[]) {
    value = value.map((ext: string) => ext.startsWith('.') ? ext : `.${ext}`)
    value = value.filter((ext: string, index: number) => value.indexOf(ext) === index) // deduplicate entries

    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.editor.autoEditExtensions',
      value: value.sort(),
      server: true
    })
  }

  get codeLens (): boolean {
    return this.$store.state.config.uiSettings.editor.codeLens
  }

  set codeLens (value: boolean) {
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.editor.codeLens',
      value,
      server: true
    })
  }

  handleReset () {
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.editor',
      value: defaultState().uiSettings.editor,
      server: true
    })
  }
}
