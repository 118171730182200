
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import ToolheadMixin from '@/mixins/toolhead'
import { Waits } from '@/globals'

@Component({})
export default class PressureAdvanceAdjust extends Mixins(StateMixin, ToolheadMixin) {
  waits = Waits

  handleSetPressureAdvance (val: number) {
    this.sendGcode('SET_PRESSURE_ADVANCE ADVANCE=' + val, this.waits.onSetPressureAdvance)
  }

  handleSetSmoothTime (val: number) {
    this.sendGcode('SET_PRESSURE_ADVANCE SMOOTH_TIME=' + val, this.waits.onSetPressureAdvance)
  }

  get isMobile () {
    return this.$vuetify.breakpoint.mobile
  }
}
