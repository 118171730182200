
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { RunoutSensor } from '@/store/printer/types'

@Component({})
export default class RunoutSensorsCard extends Mixins(StateMixin) {
  get sensors () {
    return this.$store.getters['printer/getRunoutSensors']
  }

  changeSensor (item: RunoutSensor, value: boolean) {
    const enable: number = (value) ? 1 : 0
    this.sendGcode(`SET_FILAMENT_SENSOR SENSOR=${item.name} ENABLE=${enable}`)
  }
}
