
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppBtnCollapse extends Vue {
  @Prop({ type: Boolean, default: false })
  public collapsed!: boolean

  @Prop({ type: Boolean, default: true })
  public enabled!: boolean

  @Prop({ type: Boolean, default: false })
  public inLayout!: boolean

  // emitChange (value: boolean) {
  //   this.$emit('input', value)
  // }
}
