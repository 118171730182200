
import { Component, Mixins } from 'vue-property-decorator'
import { Device } from '@/store/power/types'
import StateMixin from '@/mixins/state'
import ServicesMixin from '@/mixins/services'
import { SocketActions } from '@/api/socketActions'
import { ServiceInfo } from '@/store/server/types'

@Component({})
export default class SystemCommands extends Mixins(StateMixin, ServicesMixin) {
  get serverInfo () {
    return this.$store.getters['server/getInfo']
  }

  get hosted () {
    return this.$store.state.config.hostConfig.hosted
  }

  get powerDevices () {
    return this.$store.state.power.devices
  }

  get devicePowerComponentEnabled () {
    return this.$store.getters['server/componentSupport']('power')
  }

  get services () {
    return this.$store.getters['server/getServices'].filter((service: ServiceInfo) => service.name !== 'klipper_mcu')
  }

  async checkDialog (executableFunction: any, service: ServiceInfo, action: string) {
    if (this.printerPrinting || ['restart', 'stop'].includes(action)) {
      const res = await this.$confirm(
        this.$t(
          `app.general.simple_form.msg.confirm_service_${action}`,
          { name: service.name })?.toString(),
        { title: this.$tc('app.general.label.confirm'), color: 'card-heading', icon: '$error' }
      )
      if (res) {
        this.$emit('click')
        await executableFunction(service)
      }
    } else {
      this.$emit('click')
      await executableFunction(service)
    }
  }

  async serviceRestart (service: ServiceInfo) {
    await this.serviceRestartByName(service.name)
  }

  async serviceStart (service: ServiceInfo) {
    await this.serviceStartByName(service.name)
  }

  async serviceStop (service: ServiceInfo) {
    await this.serviceStopByName(service.name)
  }

  handleHostReboot () {
    this.$confirm(
      this.$tc('app.general.simple_form.msg.confirm_reboot_host'),
      { title: this.$tc('app.general.label.confirm'), color: 'card-heading', icon: '$error' }
    )
      .then(res => {
        if (res) {
          this.$emit('click')
          this.hostReboot()
        }
      })
  }

  handleHostShutdown () {
    this.$confirm(
      this.$tc('app.general.simple_form.msg.confirm_shutdown_host'),
      { title: this.$tc('app.general.label.confirm'), color: 'card-heading', icon: '$error' }
    )
      .then(res => {
        if (res) {
          this.$emit('click')
          this.hostShutdown()
        }
      })
  }

  async togglePowerDevice (device: Device, wait?: string) {
    const confirmOnPowerDeviceChange = this.$store.state.config.uiSettings.general.confirmOnPowerDeviceChange
    let res: boolean | undefined = true
    if (confirmOnPowerDeviceChange) {
      res = await this.$confirm(
        this.$tc('app.general.simple_form.msg.confirm_power_device_toggle'),
        { title: this.$tc('app.general.label.confirm'), color: 'card-heading', icon: '$error' }
      )
    }
    if (res) {
      const state = (device.status === 'on') ? 'off' : 'on'
      SocketActions.machineDevicePowerToggle(device.device, state, wait)
    }
  }

  getPowerIcon (device: Device) {
    switch (device.status) {
      case 'error': {
        return '$error'
      }
      case 'init': {
        return '$dots'
      }
      case 'on': {
        return '$powerOn'
      }
      case 'off': {
        return '$powerOff'
      }
    }
  }

  getPowerButtonText (device: Device): string {
    switch (device.status) {
      case 'error': {
        return `${device.device} [error]`
      }
      default: {
        return `${device.device}`
      }
    }
  }
}
