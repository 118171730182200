
import { authApi } from '@/api/auth.api'
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { EventBus } from '@/eventBus'
import { VForm } from '@/types'

@Component({})
export default class UserPasswordDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  public value!: boolean

  @Ref('form')
  readonly form!: VForm

  currentPassword = ''
  password = ''
  error = false
  loading = false

  valid = false

  rules = {
    required: (v: string) => (v && v !== undefined && v !== '') || this.$t('app.general.simple_form.error.required'),
    password: (v: string) => (v && v.toLowerCase() !== this.currentUser.toLowerCase()) || this.$t('app.general.simple_form.error.password_username'),
    min: (v: string) => (v && v !== undefined && v.length >= 4) || this.$t('app.general.simple_form.error.min', { min: 4 })
  }

  get currentUser () {
    const currentUser = this.$store.getters['auth/getCurrentUser']
    return (currentUser && currentUser.username) ? currentUser.username : ''
  }

  @Watch('value')
  onOpen (val: boolean) {
    if (val) {
      this.currentPassword = ''
      this.password = ''
      this.error = false
      this.loading = false
      if (this.form) this.form.reset()
    }
  }

  handleSave () {
    if (this.valid) {
      this.loading = true
      authApi.changePassword(this.currentPassword, this.password)
        .then(() => {
          EventBus.$emit(this.$tc('app.general.msg.password_changed'), { timeout: 2000 })
          this.$emit('input', false)
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => (this.loading = false))
    }
  }
}
