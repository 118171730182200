
import { Component, Vue, Prop } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    QrcodeVue
  }
})
export default class AppQrCode extends Vue {
  @Prop({ type: String, default: '' })
  public value!: string

  @Prop({ type: Number, default: 260 })
  public size!: number
}
