
import { Component, Mixins } from 'vue-property-decorator'
import FilesMixin from '@/mixins/files'
import StateMixin from '@/mixins/state'
import getFilePaths from '@/util/get-file-paths'
import JobHistoryItemStatus from '@/components/widgets/history/JobHistoryItemStatus.vue'

@Component({
  components: {
    JobHistoryItemStatus
  }
})
export default class ReprintTab extends Mixins(StateMixin, FilesMixin) {
  get history () {
    return this.$store.getters['history/getUniqueHistory'](3)
  }

  getFilePaths (filename: string) {
    return getFilePaths(filename, 'gcodes')
  }

  get headers () {
    const headers = [
      { text: '', value: 'data-table-icons', sortable: false, width: '24px' },
      { text: this.$tc('app.general.table.header.name'), value: 'filename', sortable: false },
      { text: this.$tc('app.general.table.header.status'), value: 'status', sortable: false },
      { text: this.$tc('app.general.table.header.print_duration'), value: 'print_duration', sortable: false }
      // { text: this.$tc('app.general.table.header.total_duration'), value: 'total_duration', sortable: false },
      // { text: this.$tc('app.general.table.header.start_time'), value: 'start_time', sortable: false }
    ]
    return headers
  }
}
