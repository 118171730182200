
import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { AppTableHeader } from '@/types'

@Component({})
export default class AppColumnPicker extends Mixins(StateMixin) {
  @Prop({ type: String, required: true })
  public keyName!: string

  @Prop({ type: Array, required: true })
  public headers!: AppTableHeader[]

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  value: any[] = []

  handleToggleHeader (header: AppTableHeader) {
    header.visible = !header.visible
    this.$store.dispatch('config/updateHeader', { name: this.keyName, header })
  }
}
