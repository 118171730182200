
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class McuLoadChart extends Vue {
  ready = false

  @Prop({ type: String, required: true })
  public mcu!: string

  get chartData () {
    return this.$store.state.charts[this.mcu] || []
  }

  get options () {
    const o = {
      ...this.$store.getters['charts/getBaseChartOptions']({
        load: '%',
        awake: 's',
        bw: 'b'
      }),
      series: this.series
    }
    o.yAxis.max = (value: any) => {
      // Grab the max, and add some buffer.
      if (value.max <= 10) return 15
      if (value.max <= 20) return 25
      if (value.max <= 30) return 35
      if (value.max <= 40) return 45
      if (value.max <= 50) return 55
      if (value.max <= 50) return 55
      if (value.max <= 60) return 65
      if (value.max <= 70) return 75
      if (value.max <= 80) return 85
      return value.max
    }
    // o.yAxis.max = 'dataMax'
    return o
  }

  get series () {
    const load = this.$store.getters['charts/getBaseSeries']({
      name: 'load',
      encode: { x: 'date', y: 'load' }
    })

    const awake = this.$store.getters['charts/getBaseSeries']({
      name: 'awake time',
      encode: { x: 'date', y: 'awake' }
    })

    // const bw = this.$store.getters['charts/getBaseSeries']({
    //   name: 'bandwidth',
    //   encode: { x: 'date', y: 'bw' }
    // })

    return [load, awake]
  }

  @Watch('chartData', { immediate: true })
  onChartData (data: any) {
    if (data && data.length > 0) this.ready = true
  }
}
