
import { MCU } from '@/store/printer/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class McuConstantsDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  public value!: boolean

  @Prop({ type: Object, required: true })
  public mcu!: MCU

  get constants () {
    return Object.entries(this.mcu.mcu_constants)
  }
}
