
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class VersionStatus extends Vue {
  @Prop({ type: Boolean, default: false })
  public hasUpdate!: boolean

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ type: Boolean, default: false })
  public dirty!: boolean

  @Prop({ type: Boolean, default: true })
  public valid!: boolean
}
