
import { Component, Mixins, Ref } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import AppSetting from '@/components/ui/AppSetting.vue'
import { ParkPosition, TimelapseSettings } from '@/store/timelapse/types'
import { SocketActions } from '@/api/socketActions'
import ParkExtrudeRetractSettings from '@/components/settings/timelapse/subsettings/ParkExtrudeRetractSettings.vue'
import CustomParkPositionSettings from '@/components/settings/timelapse/subsettings/CustomParkPositionSettings.vue'
import { VInput } from '@/types'

@Component({
  components: {
    CustomParkPositionSettings,
    ParkExtrudeRetractSettings,
    AppSetting
  }
})
export default class LayerMacroSettings extends Mixins(StateMixin) {
  @Ref('parkTimeElement')
  readonly parkTimeElement?: VInput

  @Ref('parkTravelSpeedElement')
  readonly parkTravelSpeedElement?: VInput

  @Ref('parkPosDZElement')
  readonly parkPosDZElement?: VInput

  rules = {
    numRequired: (v: number | string) => v !== '' || this.$t('app.general.simple_form.error.required'),
    validNum: (v: string) => !isNaN(+v) || this.$t('app.general.simple_form.error.invalid_number'),
    numMin: (min: number) => (v: number) => v >= min || this.$t('app.general.simple_form.error.min', { min }),
    numMax: (max: number) => (v: number) => v <= max || this.$t('app.general.simple_form.error.min', { max })
  }

  get parkPositions (): {text: string, value: ParkPosition}[] {
    const values: ParkPosition[] = ['front_left', 'front_right', 'center', 'back_left', 'back_right', 'custom']

    return values.map(value => ({ text: this.$tc(`app.timelapse.setting.parkpos.${value}`), value }))
  }

  get parkheadBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('parkhead')
  }

  get parkhead (): boolean {
    return this.settings?.parkhead
  }

  set parkhead (value: boolean) {
    SocketActions.machineTimelapseSetSettings({ parkhead: value })
  }

  get parkposBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('parkpos')
  }

  get parkpos (): ParkPosition {
    return this.settings?.parkpos
  }

  set parkpos (value: ParkPosition) {
    SocketActions.machineTimelapseSetSettings({ parkpos: value })
  }

  get parkTimeBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('park_time')
  }

  get parkTime (): number {
    return this.settings?.park_time * 1000
  }

  setParkTime (value: number) {
    if (this.parkTimeElement?.validate()) {
      SocketActions.machineTimelapseSetSettings({ park_time: value / 1000 })
    }
  }

  get parkTravelSpeedBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('park_travel_speed')
  }

  get parkTravelSpeed (): number {
    return this.settings?.park_travel_speed
  }

  setParkTravelSpeed (value: number) {
    if (this.parkTravelSpeedElement?.validate()) {
      SocketActions.machineTimelapseSetSettings({ park_travel_speed: value })
    }
  }

  get parkPosZBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('park_custom_pos_dz')
  }

  get parkPosZ (): number {
    return this.settings?.park_custom_pos_dz
  }

  setParkPosZ (value: number) {
    if (this.parkPosDZElement?.validate()) {
      SocketActions.machineTimelapseSetSettings({ park_custom_pos_dz: value })
    }
  }

  get firmwareRetractBlocked (): boolean {
    return this.$store.getters['timelapse/isBlockedSetting']('fw_retract')
  }

  get firmwareRetract (): boolean {
    return this.settings?.fw_retract
  }

  set firmwareRetract (value: boolean) {
    SocketActions.machineTimelapseSetSettings({ fw_retract: value })
  }

  get settings (): TimelapseSettings {
    return this.$store.getters['timelapse/getSettings']
  }

  subtitleIfBlocked (blocked: boolean): string {
    return blocked ? this.$tc('app.timelapse.tooltip.managed_by_moonraker') : ''
  }
}
