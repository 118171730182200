
import { ArtifactVersion, HashVersion, OSPackage } from '@/store/version/types'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class VersionInformationDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  public value!: boolean

  @Prop({ type: Object })
  public component!: HashVersion | ArtifactVersion | OSPackage

  // For HashVersions or ArtifacVersions, show the commit history.
  // For type system, show the packages available to update.
  // For type client, just show the release notes if we can.

  get commitHistory () {
    return this.$store.getters['version/getCommitHistory'](this.component.key)
  }

  get baseUrl () {
    if ('owner' in this.component) {
      return `https://github.com/${this.component.owner}/${this.component.key}`
    }
    return ''
  }
}
