
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { SocketActions } from '@/api/socketActions'

@Component({
  components: {}
})
export default class EndStopsCard extends Mixins(StateMixin) {
  get endStops () {
    return this.$store.getters['printer/getEndstops']
  }

  // Default state is an empty object.
  get hasEndStops () {
    return (Object.keys(this.endStops).length > 0)
  }

  queryEndstops () {
    SocketActions.printerQueryEndstops()
  }

  destroyed () {
    this.$store.commit('printer/setClearEndStops')
  }
}
