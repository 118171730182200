import { render, staticRenderFns } from "./SystemLayout.vue?vue&type=template&id=5fd3c87e&"
import script from "./SystemLayout.vue?vue&type=script&lang=ts&"
export * from "./SystemLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports