import { render, staticRenderFns } from "./AuthSettings.vue?vue&type=template&id=7333d8f8&"
import script from "./AuthSettings.vue?vue&type=script&lang=ts&"
export * from "./AuthSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports