
import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { FileDownload } from '@/store/files/types'

@Component({})
export default class FileSystemDownloadDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: false })
  public value!: boolean

  @Prop({ type: Object })
  public file!: FileDownload
}
