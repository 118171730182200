
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import FileSystem from '@/components/widgets/filesystem/FileSystem.vue'
import CollapsableCard from '@/components/common/CollapsableCard.vue'
import TimelapseStatusCard from '@/components/widgets/timelapse/TimelapseStatusCard.vue'
import TimelapseSettingsCard from '@/components/widgets/timelapse/TimelapseSettingsCard.vue'
import TimelapseRenderSettingsDialog from '@/components/widgets/timelapse/TimelapseRenderSettingsDialog.vue'

@Component({
  components: {
    TimelapseRenderSettingsDialog,
    CollapsableCard,
    FileSystem,
    TimelapseStatusCard,
    TimelapseSettingsCard
  }
})
export default class Timelapse extends Mixins(StateMixin) {
  renderDialogOpen = false
  renderDialogRenderable = false

  openRenderDialog (renderable = false) {
    this.renderDialogRenderable = renderable
    this.renderDialogOpen = true
  }
}
