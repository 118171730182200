
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import FileSystem from '@/components/widgets/filesystem/FileSystem.vue'
import CollapsableCard from '@/components/common/CollapsableCard.vue'
import AppSlider from '@/components/ui/AppSlider.vue'
import AppSetting from '@/components/ui/AppSetting.vue'
import { RenderStatus, TimelapseLastFrame, TimelapseSettings } from '@/store/timelapse/types'
import { SocketActions } from '@/api/socketActions'
import AppBtn from '@/components/ui/AppBtn.vue'
import CameraItem from '@/components/widgets/camera/CameraItem.vue'

@Component({
  components: {
    CameraItem,
    AppBtn,
    AppSetting,
    AppSlider,
    CollapsableCard,
    FileSystem
  }
})
export default class StatusCard extends Mixins(StateMixin) {
  selectedFrameNumber = 0

  saveFrames () {
    SocketActions.machineTimelapseSaveFrames(this.waits.onTimelapseSaveFrame)
  }

  get savingFrames () {
    return this.hasWait(this.waits.onTimelapseSaveFrame)
  }

  get selectedFrame () {
    return this.selectedFrameNumber || this.frameCount || 0
  }

  set selectedFrame (value: number) {
    this.selectedFrameNumber = value === this.frameCount ? 0 : value
  }

  get previewUrl () {
    const url = new URL(this.apiUrl ?? document.location.origin)

    if (this.lastFrame && this.lastFrame?.file) {
      let file = this.lastFrame?.file
      if (this.selectedFrame) {
        const [ext] = file?.split('.').slice(-1)
        file = `frame${this.selectedFrame.toString().padStart(6, '0')}.${ext}`
      }

      url.pathname = `/server/files/timelapse_frames/${file}`
    } else {
      return
    }

    return url.toString()
  }

  get isRendering () {
    return this.renderStatus && this.renderStatus.status !== 'success'
  }

  get frameCount () {
    return this.lastFrame?.uniqueCount
  }

  get camera () {
    return this.$store.getters['cameras/getCameraById'](this.settings.camera) ??
      { url: '/webcam/?action=snapshot', type: 'mjpgadaptive' }
  }

  get settings (): TimelapseSettings {
    return this.$store.getters['timelapse/getSettings']
  }

  get lastFrame (): TimelapseLastFrame | undefined {
    return this.$store.getters['timelapse/getLastFrame']
  }

  get renderStatus (): RenderStatus | undefined {
    return this.$store.getters['timelapse/getRenderStatus']
  }

  get apiUrl () {
    return this.$store.state.config.apiUrl
  }
}
