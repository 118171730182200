
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppBtnCollapseGroup extends Vue {
  @Prop({ type: Boolean, default: false })
  public collapsed!: boolean

  @Prop({ type: String, default: '$menu' })
  public menuIcon!: string

  get isCollapsed () {
    if (this.collapsed) return true
    return this.$vuetify.breakpoint.mdAndDown
  }

  get hasDefaultSlot () {
    return !!this.$slots.default
  }
}
