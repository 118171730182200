
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

import ConsoleCard from '@/components/widgets/console/ConsoleCard.vue'

@Component({
  components: {
    ConsoleCard
  }
})
export default class Console extends Mixins(StateMixin) {
}
